body {
	font-family: 'Baloo 2', 'Nunito', sans-serif;
	font-size: 120%;;
}
a {
	color: #E65100;
}
a:hover {
	color: #E65100;
}
.row {
    margin-right: 0;
    margin-left: 0;
}

.main-content {
	margin-bottom: 10px;
}
.footer {
	
	.bottom {
		padding: 20px 10px;
		line-height: 30px;
		padding-bottom: 30px;
	}

	.bottom a {
		color: white;
		margin: 0px 5px;
		font-size: 90%;
	}
}

.md-search {
	visibility: hidden;
}

.sections-wrapper > section {
    padding-bottom: 20px;
	width: 100%;
}

.single-blog-post .blog-post {
	float: none;
	width: auto;
}

.sections-wrapper > section {
	padding-top: 10px;
}

.table-striped td {
	border-bottom: 1px solid #EEE;
}

.table-striped td.danger, .table-striped td.success {
	border-left: 1px solid white;
	border-bottom: 1px solid white;
}

.button-row {
	padding-top: 20px;
	clear: both;
}

.clear {
	clear: both;
	display: block;
}

.page-header h1 {
	font-size: 200%;
	font-weight: bold;
}
.btn-block {
	margin-top: 25px;
	margin-bottom: 20px;
	padding: 15px;
}
.btn-half {
	margin-top: 15px;
	margin-bottom: 10px;
	padding: 6px;
	font-size: 90%;
}
.btn-social {
	> :first-child {
		top: 10px;
	}
}
.signup-or-separator {
    background: none;
    height: 40px;
    position: relative;
    text-align: center;
	clear: both;
}
.signup-or-separator--text {
    background-color: #fff;
    display: inline-block;
    padding: 8px;
	font-size: 100%;
}
.signup-or-separator hr {
	margin: -24px auto 10px;
    width: 100%;
}
.devise-layout form {
	padding-bottom: 60px;
}
.alternate-action {
	hr {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	a {
		position: relative;
		top: -10px;
	}
}
.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.help-block {
	padding-top: 5px;
}
.devise-error { 
	margin-top: 15px;
	h2 {
		display: none;
	}
}

.responsive-menu-open {
	line-height: 3.5em;
	height: 3.5em;
	float: none;
}
.header-action-button-wrapper .action-button {
    bottom: 10px;
    right: 10px;
    position: absolute;
    z-index: 99;
}

.shadow-box {
    padding: 32px 24px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
}
.shadow-box-curved {
    padding: 32px 24px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-bottom: 15px;
}
.facebook, .solid-button.facebook {
	background-color: #3B5998;
}

.button {
    text-transform: none;
}

.button-row .button {
    width: 100%;
	display: block;
}

label {
	margin-top: 5px;
	margin-bottom: 0;
}

form input {
	font-size: 120%;
}

.facebook-row {
	padding-top: 5px;
}

.link-button {
	border: 1px solid #E65100;
	padding: 0 10px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top: 5px;
}

.home-hero {
	background-color: #7c4dff;
    background: -moz-linear-gradient(-45deg, #7c4dff 0%, #8B279C 100%);
    background: -webkit-linear-gradient(-45deg, #7c4dff 0%, #8B279C 100%);
    background: linear-gradient(135deg, #7c4dff 0%, #8B279C 100%);
	
	position: relative;
	
   	.shadow-box-curved {
		margin-bottom: 0;
		padding-right: 32px;
	}
	.container {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.cta {
		color: white;
		font-size: 200%;
		line-height: 1.1em;
		padding-bottom: 15px;
		text-align: center;
		.button {
			background-color: #4CAF50;
			font-size: 60%;
		}
		span {
			
		}
	}
	.testimonial-slider {
		padding-top: 10px;
		margin-top: 16px;
		span {
			color: white;
			font-size: 60%;
		}
		.image {
			background-color: white;
			.icon {
				font-size: 240%;
				padding: 25px;
				color: #7c4dff;
			}
		}
		.button {
			background-color: #4CAF50;
			font-size: 120%;
		}
	}
	
}

.home-widgets {
	background-color: white;
	div.row {
		padding: 20px;
		background-color: white;
	}
	.banner {
		padding-top: 15px;
	}
}

.question-block {
	.question {
		h2 {
			text-transform: none;
			font-weight: bold;
			text-align: center;
			line-height: 1em;
			font-size: 500%;
		}
	}
	.adblock-message {
		margin-top: 10px;
		padding: 20px;
		border: 2px solid #900;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		border-radius: 8px;
	}
	
	label {
		width: 100%;
		padding: 8px 12px;
		padding-bottom: 10px;
		margin: 5px;
		border: 2px solid #E1BEE7;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		border-radius: 8px;
		cursor: pointer;
		
		span {
			font-size: 150%;
			position: relative;
			left: 10px;
			top: 2px;
		}
		
		input:not([type=submit]):not([type=file]) {
			width: auto;
			margin-right: 5px;
		}
	}
	
	label:hover  {
		border-color: #BBDEFB;
	}
	
	label.selected-choice {
		border-color: #C5E1A5;
	}
	
	label input:checked + span, label.selected-choice span {
		color: #4CAF50;
	}
	
	.answer {
		.button-row {
			float: none;
			.button {
				background-color: #eaa23e;
				font-size: 200%;
				cursor: pointer;
			}
			.button.disabled {
				background-color: #999;
				cursor: default;
			}
		}
	}
	.quiz-submit-preloader {
		text-align: center;
		padding: 25px 0;
	}

	.iradio_flat-green {
		position: relative;
		input {
			position: absolute; 
			opacity: 0;
		}
		ins {
			position: absolute; 
			top: 0%; 
			left: 0%; 
			display: block; 
			width: 100%; 
			height: 100%; 
			margin: 0px; 
			padding: 0px; 
			background: rgb(255, 255, 255); 
			border: 0px; 
			opacity: 0;
		}
	}
	.choices {
		margin-bottom: 10px;
		h2 {
			font-size: 500%;
			position: relative;
			top: -5px;
			margin-bottom: 25px;
		}
	}
}

.report {
	.result {
		padding: 15px;
		margin: 5px;
		border: 2px solid #E1BEE7;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		border-radius: 8px;
	}
	.wrong {
		border-color: #FFCDD2;
		h3 {
			color: #B71C1C;
		}
	}
	.correct {
		border-color: #C5E1A5;
		h3 {
			color:#4CAF50;
		}
	}
	.md {
		font-size: 140%;
	}
	p {
		margin-bottom: 8px;
	}
}

.result-row .col-md-12 {
	padding: 5px;
}

.result-bar{
	clear: both;
    border-width: 1px 1px 1px 5px;
    background-color: transparent;
    //border-color: #ef4423;
	border-color: #DDD;
    border-radius: 3px;
    padding: 15px 10px;
    margin: 25px 0;
    border-style: solid;
	font-size: 95%;
	
	.result-stats {
		float: left;
		width: 20%;
		display: none;
	}

	.result-points {
		text-align: right;
		float: right;
		width: 80%;
		padding-top: 15px;
	}
	i {
		font-size: 130%;
		color: #D22726;
		margin-right: 2px;
	}
	.result-bar-nag {
		hr {
			margin-top: 10px;
			margin-bottom: 8px;
			border: 0;
			border-top: 1px solid #EEE;
		}
		span {
			line-height: 2.5em;
			display: block;
		}
	}
}

.slide-out {
	.content {
		h5 {
			text-transform: none;
		}
	}
}

#data-credits {
	text-align: center;
	padding: 30px 20px;
	font-size: 100%;
	line-height: 150%;
	text-transform: lowercase;
	img {
		width: 200px;
	}
}

.leaderboard, .mini-profile {
	.panel {
		margin-bottom: 0;
		border: none;
		box-shadow: none;
	}
	text-align: left;
	.panel-heading {
		background-color: #847AD1;
		font-weight: bold;
		font-size: 120%;
		padding: 8px 16px;
		h4 {
			color: white;
			i {
				font-size: 160%;
			}
			text-transform: none;
			margin: 0;
			a.today {
				color: white;
			}
		}
	}
	.without-padding {
		padding: 0;
	}
	.panel-body {
		font-size: 130%;
		i {
			font-size: 130%;
			border: 1px solid #DDD;
			padding: 6px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			background-position: center center;
			vertical-align: bottom;
		}
		small {
			font-size: 80%;
			display: block;
		}
		span {
			margin: 0;
			padding: 0;
			line-height: 1em;
			vertical-align: auto;
			position: relative;
			top: -2px;
		}
		.list-action-right {
			float: right;
			font-weight: bold;
			font-size: 130%;
			padding-top: 5px;
		}
		.list-action-left {
			float: left;
			margin-right: 10px;
			img {
				width: 40px;
				-webkit-border-radius: 6px;
				-moz-border-radius: 6px;
				border-radius: 6px;
				padding: 0;
				margin: 0;
			}
		}
		li {
			clear: both;
			padding: 5px;
			padding-right: 8px;
			background-color: white;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			margin-top: 4px;
			margin-bottom: 4px;
			border: 1px solid #EEE;
			list-style: none;
		}
	}
}

.leaderboard {
	font-size: 85%;
}

.mini-profile {
	.list-content {
		.title {
			small {
				float: right;
				a {
					margin: 0 5px;
				}
			}
		}
	}
	.user-points {
		font-size: 85%;
		span {
			line-height: 1.5em;
		}
		img {
			width: 20px;
			position: relative;
			margin-right: 2px;
		}
	}
	h4.media-heading {
		text-transform: none;
		.action-links {
			position: relative;
			top: -2px;
			a {
				margin-right: 5px;
			}
		}
	}
	.media-left {
		img {
			width: 60px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
		}
	}
}

.sidebar {
	margin-bottom: 15px;
    padding-right: 0;
    padding-left: 0;
	.widget {
		margin: 0 auto;
		padding: 16px 12px;
	}
	#sidebar-leaderboard {
		text-align: center;
		.button {
			background-color: #4CAF50;
		}
	}
	.preloader {
		display: none;
		max-width: 100%;
	}
	ul.list-material {
		padding: 0;
	}
}

.text .leaderboard {
	ul, li {
		list-style: none;
		margin-left: 0;
	}
	ul {
		padding-left: 0;
	}
	.panel-heading {
		padding-left: 0;
		padding-right: 0;
		background: none;
		h4 {
			color: #444;
		}
	}
	.panel-body i {
		padding: 12px;
	}
}

.user-profile {
	.btn {
	    display: inline-block;
	    margin-bottom: 0;
	    font-weight: normal;
	    text-align: center;
	    vertical-align: middle;
	    -ms-touch-action: manipulation;
	    touch-action: manipulation;
	    cursor: pointer;
	    background-image: none;
	    border: 1px solid transparent;
	    white-space: nowrap;
	    padding: 4px 10px;
	    font-size: 18px;
	    line-height: 1.618;
	    border-radius: 2px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    outline: 0 !important;
	    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.26);
	    -webkit-transform: translate3d(0, 0, 0);
	    transform: translate3d(0, 0, 0);
	    -webkit-transition: box-shadow 280ms cubic-bezier(0.35, 0, 0.25, 1) 200ms;
	    transition: box-shadow 280ms cubic-bezier(0.35, 0, 0.25, 1) 200ms;
	}
	.btn-green {
	    color: #ffffff;
	    background-color: #4bae4f;
	    border-color: #50b354;
		font-size: 110%;
	}
	.btn-xs {
		padding: 1px 5px;
	    font-size: 12px;
	    line-height: 1.5;
	    border-radius: 3px;
	}
	.btn-sm {
		padding: 5px 10px;
	    font-size: 12px;
	    line-height: 1.5;
	    border-radius: 3px;
	}
	.btn-verify {
		position: relative;
		top: -4px;
	}
	.verified-number {
		border-radius: 3px;
	    font-size: 12px;
	    background-color: #fff;
	    border: 1px solid #e0e0e0;
	    color: #888;
	    padding: 4px 8px;
	    line-height: 18px;
	    font-weight: 500;
	    white-space: nowrap;
	    text-align: center;
	    min-width: 76px;
	    color: #53b427;
	    border-color: #53b427;
	    background-color: #f5fbf2;
		position: relative;
		top: -4px;
	}
	.user-profile-section {
		width: 25%;
		float: left;
		margin: 0;
		padding: 0;
		padding-top: 15px;
		position: relative;
	}
	.user-profile-detail {
		width: 70%;
	}
	dl {
		dt, dd {
			display: inline-block;
			width: 25%;
			margin-bottom: 5px;
			padding-bottom: 5px;
			border-bottom: 1px solid #EEE;
		}
		dd {
			width: 70%;
		}
		margin-bottom: 25px;
	}
	dl.private-data {
		dt {
			width: 95%;
			border-bottom: none;
			margin-bottom: 0;
		}
		dd {
			width: 100%;
			padding-left: 0;
			margin-bottom: 20px;
			margin-top: 0;
			.input-group {
				margin-bottom: 0;
			}
			position: relative;
			top: -1px;
		}
	}
	dl.registration-form dt,dl.registration-form dd {
		border-left: 2px solid #DDD;
		padding-left: 15px;
	}
	.user-profile-avatar {
		text-align: center;
		img {
			width: 80px;
		}
		a {
			width: 80%;
			margin: 15px 0;
			font-size: 100%;
		}
		.avatar-photo {
			a {
				width: 100%;
				text-align: center;
				margin-top: 5px;
				margin-bottom: 0;
			}
		}
		.avatar-photo-button {
			display: none;	
		}
	}
	.user-points {
		font-size: 105%;
		span, span strong {
			vertical-align: top;
		}
		span {
			display: inline-block;
			margin-top: 3px;
			margin-left: 5px;
		}
		img {
			width: 30px;
		}
		margin-bottom: 20px;
		display: block;
		position: relative;
		top: -5px;
	}
	form {
		dd {
			border-bottom: none;
		}
		.long-text {
			width: 70%;
		}
		select {
			width: 50%;
		}
		#error_explanation {
			background-color: transparent;
		    border: 1px solid #d9534f;
		    color: #d43f3a;
			padding: 15px;
			margin-bottom: 15px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			ul {
				margin: 0;
			}
			h2 {
				display: none;
			}
		}
	}
	.referral-link {
		padding-bottom: 15px;
		margin-bottom: 10px;
		h5 {
			margin-bottom: 5px;
		}
		small {
			display: block;
		}
	}
}

.progress {
	margin-bottom: 20px;
	.progress-bar {
		background-color: #4CAF50;
	}
}

.reward-list {
	line-height: 1.8em;
}

#profile-setup-message {
	border: 2px solid #FFA726;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	padding: 15px;
	margin-bottom: 15px;
}

section.page .col-md-8 .single-blog-post form {
	.choices {
		position: relative;
		right: 4px
	}
	.true-false-choices {
		h2 {
			font-size: 400%;
		}
	}
}

.highlight {
	font-size: 120%;
	line-height: 1.6em;
	padding-left: 16px;
	border-left: 2px solid #BBDEFB;
}

.share-bar {
	padding-top: 8px;
	.share-button {
		float: right;
		padding: 10px;
		padding-right: 0;
		font-weight: bold;
	}
}

.sharethis-inline-share-buttons {
	margin-top: 15px;
	margin-left: 10px;
}

.share-social {
	
	.col-sm-12 {
		display: -webkit-box;
	  	display: -moz-box;
	  	display: -ms-flexbox;
	  	display: -webkit-flex;
	  	display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px 0 15px 0;
		i {
			font-size: 130%;
		}
		span {
			margin-left: 10px;
		}
		a {
			width: 32%;
			padding: 6px;
		}
		.share-facebook {
			background-color: #3B5998;
		}
		.share-twitter {
			background-color: #55acee;
		}
		.share-email {
			background-color: #ffbd00;
		}
	}
}

.slide-out {
	a:hover {
		color: white;
	}
}

.new_battle, .verify-battle {
	dd {
		width: 100%;
	}
	.btn-green {
	    color: #ffffff;
	    background-color: #4bae4f;
	    border-color: #50b354;
	    font-size: 110%;
	}
}
#error_explanation {
	ul {
		list-style: none;
		margin-left: 5px;
		padding-left: 0;
		li {
			margin: 0;
		}
	}
}
header .right-icons span {
	font-size: 60%;
	text-transform: lowercase;
	position: relative;
	top: -3px;
	cursor: pointer;
}
.header-open-search {
	display: none;
}
.battle-start {
	img {
		width: 80px;
		border-radius: 100%;
		border: 5px solid #FFF;
	}
	.blue-corner, .red-corner {
		background-color: #0D47A1;
		padding: 60px 20px;
		border-right: 1px solid #f5f5f5;
		.image {
			float: left;
			padding-right: 12px;
		}
		span {
			display: block;
			padding-top: 30px;
			color: white;
			font-size: 160%;
		}
		em {
			padding: 8px 10px;
			font-size: 150%;
			background-color: white;
			position: absolute;
			border-radius: 100%;
			left: -22px;
			font-style: normal;
			background-color: #f5f5f5;
		}
	}
	.red-corner {
		background-color: #B71C1C;
		border-right: none;
		border-left: 1px solid #f5f5f5;
		.image {
			float: right;
			padding-right: 0;
			padding-left: 12px;
		}
		span {
			float: right;
		}
	}
}
.no-padding {
	margin: 0;
	padding: 0;
	.blog-post .blog-post-content {
		padding: 0 !important;
		margin-top: -16px;
	}
}

.lets-play {
	padding: 30px 10px 20px 10px;
	.button {
	    background-color: #4CAF50;
	}
}

.battle-message {
	margin: 10px;
	margin-top: 20px;
	font-size: 120%;
	text-align: center;
	background-color: white;
}

.battle-score {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #847AD1;
	margin-bottom: 10px;
	align-items: center;
	.row {
		width: 40%;
		display: block;
		div {
			padding: 0;
			font-weight: bold;
			img {
				margin: 0;
				display: inline;
			}
		}
	}
	.score {
		text-align: center;
		.battle-status {
			display: block;
			background-color: #847AD1;
			padding: 0 8px;
			color: white;
		}
		.score-number {
			font-size: 110%;
			font-weight: bold;
		}
	}
	.image {
		img {
			width: 40px;
		}
	}
	
	.correct-play {
		color: #4CAF50;
	}
	
	.wrong-play {
		color: #E57373;
	}
	
}

.battle-status {
	p {
		border-bottom: 2px solid #EEE;
		padding-bottom: 10px;
	}
}

.white-space {
	height: 20px;
}

#points-diff {
	float: right;
	font-family: 'Nunito', sans-serif;
	font-weight: bold;
}

.home-tagline {
	font-family: 'Nunito', sans-serif;
	font-weight: bold;
	text-transform: lowercase;
	font-size: 110%;
	padding: 15px;
	display: block;
}

#contact-nickname {
	display: none;
}

.nav-tabs {
    border-bottom: none;
	margin-bottom: 30px;
	margin-top: 0px;
	font-size: 110%;
}
.nav-tabs li {
	width: 33.334%;
	text-align: center;
}	
.nav-tabs li a {
	background-color: #847AD1;
    margin-top: 12px;
	color: white;
	font-weight: bold;
}
.nav-tabs li a.active, .nav-tabs li.active a, .nav-tabs li a:hover, .nav-tabs li a {
	border: none !important;
	border-bottom: 2px solid #847AD1 !important;
}
.nav-tabs>li>a {
    margin-right: 5px;
}	
.nav-tabs li a:hover {
	color: #333;
	background-color: white;
}

.subsite-links {
	margin-right: 20px;
	text-transform: capitalize !important;
}

.main-nav ul>li.subsite-anchor {
	display: none;
}

#copy-referral-link {
	margin-top: 5px;
}

#profile-setup-message .copied {
	color: green;
	position: relative;
	top: 3px;
	left: 5px;
	display: none;
}

header .title {
	text-align: center;
	float: none;
}


@media only screen and (max-width: 919px) {

	.col-sm-12 {
		padding-right: 0;
	    padding-left: 0;
	}
	.col-md-8 {
		padding-right: 0;
		padding-left: 0;
	}

	.header .bottom .title {
		font-size: 24px;
	}

	.portfolio-details {
		padding: 16px 12px;
	}
	
	.single-blog-post .blog-post .blog-post-content {
	    background: #fff;
	    padding: 24px 20px;
	    box-shadow: 0 2px 4px rgba(0,0,0,.25);
	}
	.sections-wrapper > section {
		padding: 0;
	}
	.home-hero {
		.shadow-box-curved {
			margin-left: 15px;
			margin-right: 15px;
			padding-left: 15px;
			padding-right: 23px;
		}
		.testimonial-slider {
			.image {
				display: none;
			}
			
		}
	}
	
	.user-profile {
		.user-profile-section {
			width: 100%;
			float: none;
		}
		.user-profile-avatar {
			a {
				width: 60%;
			}
		}
	}
	
	.home-widgets {
		.text {
			border-bottom: 3px solid #EEE;
			margin-bottom: 15px;
		}
		.banner {
			padding-top: 0;
		}
	}
	.responsive-nav ul li {
		font-size: 130%;
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 479px) {
	section.page {
		.container, .col-md-8 {
			padding-right: 0;
			padding-left: 0;
			form {
				.choices, .button-actions {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
			.single-blog-post {
				padding-left: 0;
				padding-right: 0;
				.blog-post-content {
				    padding: 20px 12px;
				}
			}
		}
		
		.user-profile form .long-text, .user-profile form select {
		    width: 100%;
		}
	}
	.question-block .choices {
		padding-left: 5px;
		padding-right: 10px;
	}
	.answer .button-row {
		position: relative;
		left: 3px;
	}
	.share-social .col-sm-12 a {
		font-size: 90%;
		span {
			margin-left: 5px;
		}
	}
	
	.battle-start {
		img {
			display: inline;
		}
		.blue-corner, .red-corner {
			.image {
				float: none;
				padding: 0;
				text-align: center;
			}
			span {
				float: none;
				text-align: center;
				padding-top: 20px;
				font-size: 130%;
			}
		}
	}
	
	.result-row .col-md-12 {
		padding: 0;
		h3 {
			font-size: 120%;
		}
	}
	
	.home-hero .shadow-box-curved {
		margin-left: 0;
		margin-right: 0;
		padding-left: 5px;
		padding-right: 8px;
		.question h2 {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
	
	.home-tagline {
		font-size: 100%;
		padding: 5px;
	}
	
	.home-widgets {
		div.row {
			padding-left: 0;
			padding-right: 0;
			.col-md-4 {
				padding-left: 0;
				padding-right: 0;
			}
		}
		
	}
	
	.subsite-links {
		
	}
	
	.main-nav ul>li.subsite-anchor {
		display: inline-block;
	}
}
