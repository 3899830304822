/**
*
* Contents
* 
* 01. General Styles
* 02. Buttons
* 03. Forms
* 04. Alerts
* 05. Progress Bar
* 06. Header
* 07. Footer
* 08. Social Icons
* 09. Sections
* 10. Widgets
* 11. Signature
* 12. Services
* 13. Experience
* 14. Testimonials
* 15. Education
* 16. Icon Box
* 17. Portfolio
* 18. Blog
* 19. Error Page
* 
**/


/* ==========================================================================
   01. General Styles
   ========================================================================== */
html, body {
	overflow-x: hidden;
}
body {
	background: #f5f5f5;
	font-size: 14px;
	line-height: 24px;
	color: #666;
	font-weight: 400;
}
p {
	margin-bottom: 24px;
}
blockquote {
	margin: 24px 0;
	margin-left: 72px;
	border-left: 4px solid #7c4dff;
	padding: 0;
	padding-left: 38px;
	font-size: 16px;
	line-height: 28px;
	color: #232323;
	font-weight: 500;
	text-transform: uppercase;
}
blockquote p {
	position: relative;
}
blockquote p:before {
	content: '“';
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	line-height: 28px;
	position: absolute;
	left: -12px;
	top: 0;
}
blockquote p:after {
	content: '”';
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	line-height: 28px;
}
blockquote footer {
	font-size: 13px;
	line-height: 28px;
	text-transform: uppercase;
	color: #7c4dff;
	margin-top: 12px;
}
blockquote footer:before {
	content: '';
}
a {
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
	outline: none !important;
	color: #7e50ff;
}
a:hover {
	color: #7e50ff;
}
a:focus {
	text-decoration: none;
}
a img {
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
a:hover img {
	opacity: .75;
}
.list-unstyled li {
	margin-bottom: 12px;
}
.list-icons {
	padding-left: 0;
	margin-left: 36px;
	list-style-type: none;
}
.list-icons > li {
	position: relative;
}
.list-icons > li > i {
	position: absolute;
	left: -36px;
	width: 36px;
	top: 2px;
	text-align: center;
	color: #232323;
	font-size: 18px;
	vertical-align: middle;
}
.list-icons.purple > li > i {
	color: #7e50ff;
}
.bold-list {
	font-weight: 500;
	text-transform: uppercase;
}
.bold-list li {
	margin-bottom: 12px;
}
h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
	font-weight: 700;
	color: #232323;
}
h1 {
	font-size: 50px;
	line-height: 60px;
	margin-top: 0;
	margin-bottom: 18px;
}
h2 {
	font-size: 28px;
	line-height: 26px;
	margin-top: 0;
	margin-bottom: 18px;
}
h3 {
	font-size: 24px;
	line-height: 26px;
	margin-top: 0;
	margin-bottom: 18px;
	font-weight: 500;
}
h4 {
	font-size: 20px;
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 12px;
}
h5 {
	font-size: 15px;
	line-height: 32px;
	margin-top: 0;
	margin-bottom: 24px;
}
h6 {
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
}
h3.small-margin-bottom {
	margin-bottom: 6px;
}
.map {
	width: 100%;
	height: 426px;
	margin-top: 72px;
}

/* ==========================================================================
   02. Buttons
   ========================================================================== */
.button {
	font-weight: 500;
	text-transform: uppercase;
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
	outline: none !important;
}
.button:hover {
	text-decoration: none;
}
.button i {
	font-size: 24px;
	vertical-align: middle;
}
.button.icon-left i {
	margin-right: 12px;
}
.button.icon-right i {
	margin-left: 12px;
}
/*.button-row {
	margin-top: 48px;
}
.button-row .button {
	margin-right: 32px;
	margin-bottom: 12px;
}*/
@media all and (max-width: 1024px) {
	.button-row .button {
		display: block;
		margin-right: 0;
	}
}
/*==========  Solid Button  ==========*/
.solid-button {
	font-size: 15px;
	line-height: 26px;
	padding: 11px 29px;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 2px rgba(0,0,0,.3);
	text-align: center;
}
.solid-button:hover {
	box-shadow: 0 3px 6px rgba(0,0,0,.3);
}
.solid-button.purple {
	background: #7c4dff;
	color: #fff;
}
.solid-button.white {
	background: #fff;
	color: #232323;
}
.solid-button.dark {
	background: #232323;
	color: #fff;
}
.solid-button.white-purple {
	background: #fff;
	color: #232323;
}
.solid-button.white-purple:hover {
	background: #7c4dff;
	color: #fff;
}
.solid-button.small {
	font-size: 12px;
	padding: 2px 20px;
}
/*==========  Link Button  ==========*/
.link-button {
	font-size: 13px;
	line-height: 32px;
}
.link-button:hover {
	opacity: .75;
}
.link-button.white {
	color: #fff;
}
/*==========  Action Button  ==========*/
.action-button {
	background: #7c4dff;
	color: #fff;
	font-size: 24px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 100%;
	display: inline-block;
	text-align: center;
	box-shadow: 0 4px 12px rgba(0,0,0,.13), 0 6px 10px rgba(0,0,0,.3);
}
.action-button:hover {
	box-shadow: 0 6px 18px rgba(0,0,0,.13), 0 9px 15px rgba(0,0,0,.3);
}

/* ==========================================================================
   03. Forms
   ========================================================================== */
input:not([type=submit]):not([type=file]), select, textarea {
	border: none;
	border-bottom: 1px solid #d7d7d7;
	background: none;
	width: 100%;
	padding: 2px 6px;
	outline: none !important;
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
input:not([type=submit]):not([type=file]):focus, select:focus, textarea:focus {
	border-color: #7c4dff;
}
.input-group {
	margin-bottom: 28px;
}
.form-horizontal .control-label {
	text-align: left;
}

/* ==========================================================================
   04. Alerts
   ========================================================================== */
.alert {
	box-shadow: 0 2px 2px rgba(0,0,0,.3);
	border: none;
	margin-bottom: 0;
	margin-top: 24px;
	margin-bottom: 20px;
	font-size: 15px;
	line-height: 26px;
	border-radius: 3px;
	font-weight: normal;
	padding: 12px;
	padding-bottom: 10px;
}
.alert-info {
	background: #2196F3;
	color: #fff;
}
.alert-success {
	background: #4CAF50;
	color: #fff;
}
.alert-danger {
	background: #F44336;
	color: #fff;
}

/* ==========================================================================
   05. Progress Bar
   ========================================================================== */
.progress {
	height: 8px;
	margin-bottom: 36px;
	background-color: #e8e8e8;
	border-radius: 0;
	box-shadow: none;
	overflow: visible;
}
.progress-bar {
	line-height: 8px;
	background-color: #7c4dff;
	box-shadow: none;
	position: relative;
}
.progress-bar span {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #000;
	background: #fff;
	font-weight: 400;
	position: absolute;
	top: -16px;
	right: -20px;
	box-shadow: 0 3px 6px rgba(0,0,0,.3);
}
.progress-bar-label {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 13px;
	margin-bottom: 12px;
	color: #232323;
}
.circle-progress-wrapper {
	margin-bottom: 24px;
}
.circle-progress {
	display: inline-block;
	width: 200px;
	height: 200px;
	position: relative;
	float: left;
}
.circle-progress input {
	font-size: 20px !important;
	display: inline-block !important;
	width: 78px !important;
	height: 78px !important;
	line-height: 78px !important;
	border-radius: 100% !important;
	background: #fff !important;
	margin-top: 61px !important;
	margin-left: 61px !important;
	box-shadow: 0 3px 6px rgba(0,0,0,.3) !important;
	font-family: 'Roboto', sans-serif !important;
	font-weight: 500 !important;
	top: 0;
	left: 0;
}
.circle-progress-label-wrapper {
	margin-left: 240px;
	height: 200px;
}
.circle-progress-label {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 0;
	color: #232323;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media all and (max-width: 768px) {
	.circle-progress {
		float: none;
	}
	.circle-progress-label-wrapper {
		margin-left: 0;
		height: auto;
		margin-top: 12px;
	}
	.circle-progress-label {
		top: auto;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

/* ==========================================================================
   06. Header
   ========================================================================== */
.header {
	box-shadow: 0 1px 6px rgba(0,0,0,.3);
	text-transform: uppercase;
}
.header .top {
	background: #7c4dff;
	line-height: 50px;
	color: #fff;
	padding: 0 60px;
}
.header .top a {
	color: #fff;
}
.header .top a:hover {
	opacity: .75;
	text-decoration: none;
}
.header .top .available {
	float: left;
}
.header .top .available i {
	font-size: 36px;
	line-height: 50px;
	margin-right: 8px;
	vertical-align: middle;
}
.header .top .right-icons {
	float: right;
	font-size: 24px;
}
.header .top .right-icons a {
	margin-left: 12px;
}
.header .bottom {
	background: #fff;
	line-height: 70px;
	padding: 0 60px;
	color: #232323;
	font-weight: 500;
	position: relative;
  font-family: 'Nunito', sans-serif;
}
.header .bottom a {
	color: #444;
}
.header .bottom .title {
	font-size: 28px;
	float: left;
  font-weight: bold;
}
.header .bottom .title a:hover {
	opacity: .75;
	text-decoration: none;
}
.main-nav {
	float: right;
}
.main-nav ul {
	margin-bottom: 0;
}
.main-nav ul>li {
	padding: 0;
	display: inline-block;
	margin-left: -4px;
	margin-bottom: 0;
}
.main-nav ul>li a {
	padding: 0 20px;
	display: inline-block;
	line-height: 70px;
	height: 70px;
	border-bottom: 5px solid transparent;
}
.main-nav ul>li a:hover {
	border-bottom: 5px solid #7c4dff;
	text-decoration: none;
}
.main-nav ul>li.active a {
	border-bottom: 5px solid #7c4dff;
	text-decoration: none;
}
.header-action-button-wrapper {
	float: right;
	width: 30px;
	height: 70px;
	display: inline-block;
}
.header-action-button {
	position: absolute;
	bottom: -30px;
	right: 60px;
	z-index: 99;
}
.header .bottom .action-button {
	color: #fff;
}
.responsive-menu-open {
	display: none;
	float: right;
	text-transform: uppercase;
	line-height: 70px;
	height: 70px;
	text-decoration: none;
}
.responsive-menu-open:hover {
	text-decoration: none;
}
.responsive-menu {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 100%;
	min-width: 100%;
	background: #fff;
	z-index: 999;
	padding: 24px;
	overflow-y: scroll;
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
.responsive-menu.open {
	left: 0;
}
.responsive-menu-close {
	position: absolute;
	top: 24px;
	right: 24px;
	text-transform: uppercase;
	text-decoration: none;
}
.responsive-menu-close:hover {
	text-decoration: none;
}
.responsive-nav ul>li a {
	color: #222;
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
}
.responsive-nav ul>li.active a {
	color: #7c4dff;
}
.no-scroll {
	overflow: hidden;
}
@media all and (max-width: 1024px) {
	.header .top {
		padding: 0 12px;
	}
	.header .top .available span {
		display: none;
	}
	.header .bottom {
		padding: 0 12px;
	}
	.main-nav {
		display: none;
	}
	.responsive-menu-open {
		display: inline-block;
	}
	.header-action-button-wrapper {
		width: 84px;
	}
	.header-action-button {
		right: 12px;
	}
}
/*==========  Popup  ==========*/
.popup, .slide-out-popup {
	background: #fff;
	box-shadow: 0 0 8px rgba(0,0,0,.3);
	position: absolute;
	top: 47px;
	right: 35px;
	z-index: 999;
	min-width: 118px;
	display: none;
}
.slide-out-popup {
	top: 54px;
	right: 41px;
}
.popup:before, .slide-out-popup:before {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 7.5px 10px 7.5px;
	border-color: transparent transparent #fff transparent;
	position: absolute;
	top: -10px;
	right: 30px;
}
.popup .social-nav ul, .slide-out-popup .social-nav ul {
	margin-bottom: 0;
}
.popup .social-nav ul li, .slide-out-popup .social-nav ul li {
	line-height: 30px;
	margin-bottom: 0;
}
.popup .social-nav ul li a, .slide-out-popup .social-nav ul li a {
	color: #232323;
	font-size: 12px;
	line-height: 30px;
	text-transform: uppercase;
	padding: 0 20px;
	display: inline-block;
	width: 100%;
	height: 100%;
}
.popup .social-nav ul li a:hover, .slide-out-popup .social-nav ul li a:hover {
	color: #fff;
	background: #7c4dff;
	font-weight: 700;
	text-decoration: none;
}
@media all and (max-width: 1024px) {
	.popup {
		right: 2px;
	}
	.popup:before {
		right: 15px;
	}
}
/*==========  Search  ==========*/
.search-overlay {
	background: #7c4dff;
	background: rgba(124,77,255,.93);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: .5s;
	-moz-transition: .5s;
	-o-transition: .5s;
	-ms-transition: .5s;
	transition: .5s;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999;
	border-radius: 100%;
}
.search-overlay.scale {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.search {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: none;
	color: #fff;
	text-align: center;
	padding: 0 12px;
	padding-top: 144px;
	font-size: 24px;
}
.search.open {
	display: block;
}
.search-close {
	position: absolute;
	right: 48px;
	top: 48px;
	z-index: 999;
	font-size: 48px;
	color: #fff;
}
.search-close:hover {
	color: #fff;
	opacity: .75;
}
.search h4 {
	margin-bottom: 48px;
	color: #fff;
}
.search input:focus {
	border-color: #fff !important;
}
/*==========  Slide Out  ==========*/
.slide-out-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.5);
	z-index: 99;
	display: none;
}
.slide-out {
	width: 380px;
	height: auto;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	bottom: 0;
	right: -400px;
	background: #fff;
	z-index: 100;
	box-shadow: -2px 0 10px rgba(0,0,0,.4);
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
.slide-out.open {
	right: 0;
}
.slide-out-header {
	background: #7c4dff;
	padding: 30px;
	padding-top: 20px;
	color: #fff;
}
.slide-out-header a {
	color: #fff;
}
.slide-out-header a:hover {
	opacity: .75;
}
.slide-out-close {
	font-size: 24px;
}
.slide-out-header .open-search, .slide-out-header .slide-out-share {
	font-size: 24px;
	margin-left: 12px;
}
.slide-out-header .image {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	overflow: hidden;
	border: 5px solid #fff;
	background: #e2e2e2;
	box-shadow: 0 2px 6px rgba(0,0,0,.3);
	float: left;
	margin-top: 24px;
}
.slide-out-header .content {
	margin-left: 118px;
	padding-top: 18px;
	margin-top: 24px;
	margin-bottom: 24px;
}
.slide-out-header .content h5 {
	margin-bottom: 0;
	color: #fff;
}
.slide-out-header .content span {
	line-height: 32px;
	font-weight: 500;
}
.slide-out-widgets {
	padding: 30px;
	padding-top: 50px;
}
.slide-out-widget {
	margin-bottom: 60px;
}
.slide-out-widget h4 {
	margin-bottom: 24px;
	color: #111;
}
@media all and (max-width: 768px) {
	.slide-out {
		width: 100%;
		right: -110%;
	}
}

/* ==========================================================================
   07. Footer
   ========================================================================== */
.footer {
	text-align: center;
	color: #fff;
	font-weight: 500;
}
.footer .top {
	background: #232323;
	padding: 48px 0;
}
.footer .bottom {
	background: #1c1c1c;
	line-height: 50px;
}
.footer h4 {
	color: #fff;
}
.footer .social-icons {
	margin-bottom: 24px;
}

/* ==========================================================================
   08. Social Icons
   ========================================================================== */
.social-icon {
	display: inline-block;
	width: 42px;
	height: 42px;
	line-height: 42px;
	font-size: 16px;
	color: #232323;
	background: #fff;
	border-radius: 100%;
	box-shadow: 0 4px 12px rgba(0,0,0,.13);
	margin: 0 4px;
	text-align: center;
}
.social-icon:hover {
	box-shadow: 0 4px 12px rgba(0,0,0,.13), 0 6px 10px rgba(0,0,0,.3);
}
.footer .social-icon {
	box-shadow: 0 4px 12px rgba(0,0,0,.13), 0 6px 10px rgba(0,0,0,.3);
}
.social-icon:hover {
	background: #7c4dff;
	color: #fff;
}

/* ==========================================================================
   09. Sections
   ========================================================================== */
.sections {
	display: block;
	height: auto;
	overflow: hidden;
	position: static;
	word-wrap: break-word;
}
.sections-wrapper {
	display: block;
	width: auto;
	height: auto;
	overflow: hidden;
	-webkit-transition: .5s;
	-moz-transition: .5s;
	-o-transition: .5s;
	-ms-transition: .5s;
	transition: .5s;
}
.sections-wrapper > section {
	width: 1600px;
	height: auto;
	float: left;
	padding: 72px 12px;
}
.sections-wrapper > section.no-padding-bottom {
	padding-bottom: 0;
}
.sections-wrapper > section.no-padding-bottom .padding-fix {
	padding-bottom: 72px;
}
.section-img {
	max-height: 650px;
	margin: 0 auto;
}
.vertical-center {
	display: table-cell;
	vertical-align: middle;
	float: none;
}
.section-nav {
	position: fixed;
	bottom: 80px;
	right: 60px;
	z-index: 9;
}
.section-nav a {
	display: block;
	width: 58px;
	height: 58px;
	line-height: 58px;
	text-align: center;
	font-size: 36px;
	border-radius: 100%;
	background: #000;
	color: #fff;
	box-shadow: 0 4px 12px rgba(0,0,0,.13), 0 6px 10px rgba(0,0,0,.3);
	margin-top: 20px;
}
.section-nav a:hover {
	box-shadow: 0 6px 18px rgba(0,0,0,.13), 0 9px 15px rgba(0,0,0,.3);
}
.section-nav a.disabled {
	pointer-events: none;
	cursor: default;
	opacity: .75;
}
.spacer {
	margin-bottom: 48px;
}
@media all and (max-width: 1024px) {
	.vertical-center {
		display: block;
	}
	.section-img {
		max-height: 256px;
	}
	.section-nav {
		bottom: 12px;
		right: 12px;
	}
	.section-nav a {
		width: 36px;
		height: 36px;
		line-height: 36px;
		font-size: 24px;
		margin-top: 12px;
	}
}

/* ==========================================================================
   10. Widgets
   ========================================================================== */
.instagram {
	text-align: center;
}
.instagram img {
	width: 93px;
	display: inline-block;
	padding: 6px;
}

/* ==========================================================================
   11. Signature
   ========================================================================== */
.signature {
	border-bottom: 2px dashed #232323;
	position: relative;
	margin: 0 18px;
	padding-bottom: 12px;
	margin-bottom: 24px;
}
.signature:before {
	display: block;
	content: '';
	width: 14px;
	height: 14px;
	background: #fff;
	border: 4px solid #7c4dff;
	border-radius: 100%;
	position: absolute;
	left: -18px;
	bottom: -7px;
}
.signature:after {
	display: block;
	content: '';
	width: 14px;
	height: 14px;
	background: #fff;
	border: 4px solid #7c4dff;
	border-radius: 100%;
	position: absolute;
	right: -18px;
	bottom: -7px;
}

/* ==========================================================================
   12. Services
   ========================================================================== */
.service {
	background: #fff;
	text-align: center;
	padding: 48px 0;
	color: #232323;
	box-shadow: 0 2px 6px rgba(0,0,0,.2);
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
	margin-bottom: 24px;
}
.service:hover {
	background: #7c4dff;
	color: #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,.3);
}
.service .icon {
	font-size: 72px;
	line-height: 72px;
	margin-bottom: 24px;
}
.service h5 {
	margin-bottom: 0;
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
.service:hover h5 {
	color: #fff;
}

/* ==========================================================================
   13. Experience
   ========================================================================== */
.experience-block {
	position: relative;
	padding-bottom: 30px;
}
.experience-block .icon {
	display: block;
	width: 78px;
	height: 78px;
	line-height: 78px;
	text-align: center;
	background: #fff;
	border: 5px solid #e5e5e5;
	border-radius: 100%;
	font-size: 36px;
	color: #000;
	position: absolute;
	top: 0;
	left: 30%;
	margin-left: -39px;
	z-index: 2;
}
.experience-block .line {
	display: block;
	height: 100%;
	width: 2px;
	border-left: 2px dashed #4d4d4d;
	position: absolute;
	top: 0;
	left: 30%;
}
.experience-block .meta {
	width: 20%;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
	position: relative;
	float: left;
	margin-top: 39px;
}
.experience-block .meta:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 13px 10px 0 0;
	border-color: #7c4dff transparent transparent transparent;
	position: absolute;
	top: 0;
	right: -10px;
}
.experience-block .meta .year {
	display: block;
	background: #7c4dff;
	color: #fff;
	text-align: center;
	font-size: 14px;
	line-height: 36px;
	text-transform: uppercase;
	font-weight: 700;
}
.experience-block .meta .company {
	display: block;
	background: #fff;
	color: #232323;
	text-align: center;
	font-size: 15px;
	line-height: 50px;
	text-transform: uppercase;
	font-weight: 500;
}
.experience-block .content {
	width: 60%;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
	background: #fff;
	position: relative;
	padding: 20px 30px;
	float: right;
	margin-top: 39px;
}
.experience-block .content:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 13px 0;
	border-color: transparent #fff transparent transparent;
	position: absolute;
	top: 0;
	left: -10px;
}
.experience-block .content h5 {
	margin-bottom: 6px;
}
.experience-block .content p:last-of-type {
	margin-bottom: 0;
}
@media all and (max-width: 768px) {
	.experience-block .icon {
		left: 0;
		margin-left: 0;
	}
	.experience-block .line {
		left: 38px;
	}
	.experience-block .meta {
		width: 60%;
		float: right;
		margin-top: 12px;
	}
	.experience-block .meta:after {
		border-width: 0 10px 13px 0;
		border-color: transparent #7c4dff transparent transparent;
		right: auto;
		left: -10px;
	}
	.experience-block .content {
		margin-top: 12px;
		width: 80%;
		padding: 10px 20px;
	}
	.experience-block .content:after {
		border-width: 10px 0 0 13px;
		border-color: transparent transparent transparent #fff;
		top: -10px;
		left: 0;
	}
	h2 {
		font-size: 22px;
	}
	h3 {
		font-size: 19px;
	}
	h4 {
		font-size: 16px;
	}
}

/* ==========================================================================
   14. Testimonials
   ========================================================================== */
.testimonial-slider {
	text-align: center;
	font-weight: 500;
	color: #232323;
	margin-top: 24px;
}
.testimonial-slider .image {
	width: 115px;
	height: 115px;
	display: block;
	border: 5px solid #fff;
	border-radius: 100%;
	overflow: hidden;
	box-shadow: 0 2px 6px rgba(0,0,0,.3);
	margin: 0 auto;
	margin-top: 2px;
	margin-bottom: 20px;
}
.testimonial-slider .image img {
	transform-style: inherit;
}
.testimonial-slider .sep {
	width: 65px;
	height: 3px;
	margin: 0 auto;
	background: #7c4dff;
	margin-bottom: 30px;
	position: relative;
}
.testimonial-slider .sep:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 6px 0 6px;
	border-color: #7c4dff transparent transparent transparent;
	position: absolute;
	bottom: -8px;
	left: 50%;
	margin-left: -6px;
}
.testimonial-slider p {
	font-size: 18px;
	line-height: 28px;
	font-style: italic;
}
.testimonial-slider .author {
	font-size: 15px;
	text-transform: uppercase;
	margin-bottom: 32px;
	display: inline-block;
}
.owl-theme .owl-dots .owl-dot span {
	width: 30px;
	height: 6px;
	margin: 5px 7px;
	background: #fff;
	display: block;
	border-radius: 0;
	box-shadow: 0 2px 4px rgba(0,0,0,.3);
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	background: #7c4dff;
}
.owl-theme .owl-nav {
	margin-top: 0;
}

/* ==========================================================================
   15. Education
   ========================================================================== */
.education {
	padding: 132px 0;
	margin: 72px 0;
}
.education .line {
	border-bottom: 1px dashed #232323;
	position: relative;
	margin: 0 18px;
	padding-bottom: 12px;
	margin-bottom: 24px;
	position: relative;
	top: -1px;
}
.education .line:before {
	display: block;
	content: '';
	width: 14px;
	height: 14px;
	background: #fff;
	border: 4px solid #7c4dff;
	border-radius: 100%;
	position: absolute;
	left: -18px;
	bottom: -7px;
}
.education .line:after {
	display: block;
	content: '';
	width: 14px;
	height: 14px;
	background: #fff;
	border: 4px solid #7c4dff;
	border-radius: 100%;
	position: absolute;
	right: -18px;
	bottom: -7px;
}
.education .item {
	width: 25%;
	float: left;
	position: relative;
	text-align: center;
	color: #232323;
}
.education .item .marker {
	width: 22px;
	height: 22px;
	background: #fff;
	border: 6px solid #7c4dff;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -11px;
	box-shadow: 0 2px 6px rgba(0,0,0,.25);
	z-index: 2;
}
.education .item .content {
	background: #fff;
	box-shadow: 0 2px 4px rgba(0,0,0,.3);
	padding: 24px;
	position: absolute;
	top: -132px;
	left: 0;
	right: 0;
	margin: 0 12px;
}
.education .item:nth-child(even) .content {
	top: auto;
	bottom: -132px;
}
.education .item .content:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 18px 0 0 18px;
	border-color: transparent transparent transparent #fff;
	box-shadow: -2px 2px 4px rgba(0,0,0,.15);
	position: absolute;
	bottom: -9px;
	left: 50%;
	margin-left: -9px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.education .item:nth-child(even) .content:after {
	border-width: 0 18px 18px 0;
	border-color: transparent #fff transparent transparent;
	box-shadow: none;
	position: absolute;
	top: -9px;
	bottom: auto;
}
.education .item .content span {
	font-size: 13px;
	line-height: 26px;
	margin-bottom: 12px;
	text-transform: uppercase;
}
.education .item .content h4 {
	line-height: 26px;
	margin-bottom: 0;
}
.education .item .year {
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	position: relative;
	bottom: -44px;
	left: 0;
	right: 0;
	margin: 0 auto;
}
.education .item:nth-child(even) .year {
	top: -44px;
	bottom: auto;
}
@media all and (max-width: 768px) {
	.education {
		position: relative;
		padding: 0;
		margin: 18px 0;
		margin-bottom: 42px;
	}
	.education .item {
		width: 100%;
		float: none;
		margin-bottom: 24px;
	}
	.education .item:last-of-type {
		margin-bottom: 0;
	}
	.education .item .content {
		position: static;
	}
	.education .line {
		border-bottom: none;
		border-left: 1px dashed #232323;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 1px;
		margin: 0;
		padding: 0;
	}
	.education .line:before {
		top: -18px;
		bottom: -7px;
		left: -7px;
	}
	.education .line:after {
		right: auto;
		bottom: -18px;
		left: -7px;
	}
	.education .item .content {
		padding: 24px;
		position: static;
		margin: 0;
		margin-left: 36px;
	}
	.education .item .marker {
		position: absolute;
		top: 50%;
		left: 0;
		margin-left: -11px;
		margin-top: -27px;
	}
	.education .item .content:after {
		border-width: 18px 0 0 18px;
		border-color: transparent transparent transparent #fff;
		box-shadow: -1px 2px 2px rgba(0,0,0,.15);
		bottom: auto;
		left: 36px;
		top: 50%;
		margin-top: -25px;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.education .item:nth-child(even) .content:after {
		border-width: 18px 0 0 18px;
		border-color: transparent transparent transparent #fff;
		box-shadow: -1px 2px 2px rgba(0,0,0,.15);
		bottom: auto;
		left: 36px;
		top: 50%;
		margin-top: -25px;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.education .item .year {
		position: static;
		margin: 0 auto;
		padding-left: 36px;
		margin-top: 6px;
	}
}

/* ==========================================================================
   16. Icon Box
   ========================================================================== */
.icon-box {
	text-align: center;
	margin: 12px 0;
}
.icon-box .icon {
	display: inline-block;
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 48px;
	color: #232323;
	background: #fff;
	box-shadow: 0 2px 6px rgba(0,0,0,.25);
	margin-bottom: 24px;
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
.icon-box:hover .icon {
	background: #7c4dff;
	color: #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,.3);
}
.icon-box h6 {
	line-height: 12px;
	margin-bottom: 0;
}

/* ==========================================================================
   17. Portfolio
   ========================================================================== */
.portfolio-wrapper {
	position: relative;
}
.portfolio-filters {
	position: absolute;
	top: -86px;
	right: 0;
}
.portfolio-filters .button {
	margin-left: 20px;
}
.portfolio {
	margin: 0 -20px;
	margin-top: 60px;
}
.portfolio .item {
	width: 25%;
	padding: 20px;
}
.portfolio .item img {
	width: 100%;
	height: auto;
}
.portfolio .item .overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding: 20px;
	opacity: 0;
	visibility: hidden;
}
.portfolio .item:hover .overlay {
	opacity: 1;
	visibility: visible;
}
.portfolio .item .overlay .background {
	background: #7c4dff;
	background: rgba(124,77,255,.9);
	width: 100%;
	height: 100%;
	cursor: url('../images/cursor.png') 43 43, crosshair;
	box-shadow: 0 2px 6px rgba(0,0,0,.3);
}
.portfolio .item .overlay .meta {
	background: #fff;
	text-align: center;
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
	width: calc(100% - 40px);
	margin: 0 20px;
	padding: 8px 0;
}
.portfolio .item .overlay .meta .title {
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
	font-weight: 500;
	display: block;
	color: #232323;
}
.portfolio .item .overlay .meta .category {
	font-size: 14px;
	line-height: 26px;
	font-style: italic;
	font-weight: 500;
	color: #666;
}
.portfolio-load-more {
	text-align: center;
	margin-top: 48px;
}
@media all and (max-width: 1024px) {
	.portfolio .item {
		width: 50%;
		padding: 20px;
	}
	.portfolio-filters {
		position: static;
		text-align: center;
	}
	.portfolio-filters .button {
		margin-left: 12px;
		margin-bottom: 12px;
	}
	.portfolio {
		margin-top: 12px;
	}
}
@media all and (max-width: 768px) {
	.portfolio .item {
		width: 100%;
		padding: 20px;
	}
}
/* Single
   ========================================================================== */
.portfolio-button-row {
	margin-top: 7px;
	margin-bottom: 36px;
	display: block;
}
.portfolio-details {
	padding: 32px 24px;
	background: #fff;
	box-shadow: 0 2px 4px rgba(0,0,0,.25);
}
.portfolio-details p:last-of-type {
	margin-bottom: 0;
}
.portfolio-meta {
	margin-bottom: 24px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
}
.portfolio-meta span {
	display: block;
	color: #232323;
}
.portfolio-slider {
	margin-bottom: 24px;
}
.portfolio-slider.owl-theme .owl-nav [class*='owl-'] {
	padding: 0;
	margin: 0;
	background: rgba(35,35,35,.3);
	font-size: 26px;
	display: inline-block;
	width: 54px;
	height: 54px;
	line-height: 54px;
	text-align: center;
	border-radius: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
.portfolio-slider.owl-theme .owl-nav [class*='owl-']:hover {
	background: rgba(35,35,35,.75);
}
.portfolio-slider.owl-carousel .owl-controls .owl-nav .owl-prev {
	left: 0;
}
.portfolio-slider.owl-carousel .owl-controls .owl-nav .owl-next {
	right: 0;
}
@media all and (max-width: 768px) {
	.portfolio-button-row {
		margin-top: 12px;
	}
	.related-images img {
		margin-bottom: 24px;
	}
}

/* ==========================================================================
   18. Blog
   ========================================================================== */
.blog-posts.masonry {
	margin: 0 -15px;
}
.blog-posts.masonry .blog-grid-sizer {
	width: 25%;
}
.blog-posts.masonry .blog-post {
	width: 25%;
	position: relative;
	padding: 15px;
}
.blog-posts.masonry .blog-post.image-left {
	width: 50%;
}
.blog-posts.masonry .blog-post.image-right {
	width: 50%;
}
.blog-posts.masonry .blog-post .inner {
	box-shadow: 0 2px 5px rgba(0,0,0,.2);
}
.blog-posts.masonry .blog-post .image {
	width: 50%;
	height: 276px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	-ms-transition: .25s;
	transition: .25s;
}
.blog-posts.masonry .blog-post .image:hover {
	opacity: .75;
}
.blog-posts.masonry .blog-post.image-left .image {
	float: left;
}
.blog-posts.masonry .blog-post.image-right .image {
	float: right;
}
.blog-posts.masonry .blog-post.image-top .image {
	width: 100%;
	height: 306px;
}
.blog-posts.masonry .blog-post .content {
	background: #fff;
	padding: 36px 24px;
	position: relative;
	height: 276px;
}
.blog-posts.masonry .blog-post.image-left .content {
	margin-left: 50%;
}
.blog-posts.masonry .blog-post.image-right .content {
	margin-right: 50%;
}
.blog-posts.masonry .blog-post a {
	color: #232323;
}
.blog-posts.masonry .blog-post a:hover {
	text-decoration: none;
	opacity: .75;
}
.blog-posts.masonry .blog-post .date {
	color: #777;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 24px;
	margin-bottom: 6px;
	display: inline-block;
}
.blog-posts.masonry .blog-post h4 {
	font-weight: 700;
	margin: 0;
}
.blog-posts.masonry .blog-post footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-top: 1px solid #e8e8e8;
	padding-left: 28px;
	line-height: 50px;
	height: 50px;
}
.blog-posts.masonry .blog-post footer .comments-link {
	height: 50px;
	display: inline-block;
}
.blog-posts.masonry .blog-post footer .share-link {
	float: right;
	border-left: 1px solid #e8e8e8;
}
.blog-posts.masonry .blog-post footer .share-link a {
	padding: 0 18px;
	height: 50px;
	display: inline-block;
	color: #777;
}
.blog-load-more {
	text-align: center;
	margin-top: 48px;
}
@media all and (max-width: 1024px) {
	.blog-posts.masonry .blog-grid-sizer {
		width: 50%;
	}
	.blog-posts.masonry .blog-post {
		width: 50%;
	}
	.blog-posts.masonry .blog-post.image-left {
		width: 50%;
	}
	.blog-posts.masonry .blog-post.image-right {
		width: 50%;
	}
}
@media all and (max-width: 768px) {
	.blog-posts.masonry .blog-grid-sizer {
		width: 100%;
	}
	.blog-posts.masonry .blog-post {
		width: 100%;
	}
	.blog-posts.masonry .blog-post.image-left {
		width: 100%;
	}
	.blog-posts.masonry .blog-post.image-right {
		width: 100%;
	}
	.blog-posts.masonry .blog-post .image {
		width: 100%;
		height: 306px;
	}
	.blog-posts.masonry .blog-post.image-left .image {
		float: none;
	}
	.blog-posts.masonry .blog-post.image-right .image {
		float: none;
	}
	.blog-posts.masonry .blog-post.image-left .content {
		margin-left: 0;
	}
	.blog-posts.masonry .blog-post.image-right .content {
		margin-right: 0;
	}
}
/* Single Post
   ========================================================================== */
.single-blog-post .blog-header .content {
	background: #fff;
	padding: 40px;
	position: relative;
	height: 390px;
	box-shadow: 0 2px 5px rgba(0,0,0,.2);
	float: left;
	width: 33.33%;
}
.single-blog-post .blog-header .image {
	width: 66.66%;
	height: 390px;
	float: right;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.single-blog-post .blog-header .content .date {
	color: #7c4dff;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 24px;
	margin-bottom: 12px;
	display: inline-block;
	font-weight: 500;
}
.single-blog-post .blog-header .content h4 {
	font-weight: 700;
	margin-bottom: 12px;
	font-size: 18px;
	line-height: 26px;
}
.single-blog-post .blog-header .content .meta {
	color: #666;
	font-size: 13px;
	line-height: 26px;
	text-transform: uppercase;
}
.single-blog-post .blog-header .content .meta a {
	color: #666;
}
.single-blog-post .blog-header .content .meta a:hover {
	text-decoration: none;
}
.single-blog-post .blog-header .content .footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 40px;
	padding-bottom: 24px;
}
.single-blog-post .blog-details {
	float: left;
	width: 33.33%;
	padding-right: 52px;
}
.single-blog-post .blog-details .section {
	padding: 36px 0;
	border-bottom: 1px dashed #232323;
}
.single-blog-post .blog-details .section:last-of-type {
	border-bottom: none;
}
.single-blog-post .blog-details h5 {
	margin-bottom: 0;
}
.single-blog-post .blog-details .author {
	margin-top: 24px;
}
.single-blog-post .blog-details .author .image {
	display: block;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	overflow: hidden;
	margin-bottom: 18px;
}
.single-blog-post .blog-details .author .title {
	font-size: 13px;
	line-height: 24px;
	font-weight: 500;
	color: #232323;
	text-transform: uppercase;
	margin-bottom: 12px;
	display: inline-block;
}
.single-blog-post .blog-details .social-icons {
	margin-top: 24px;
}
.single-blog-post .blog-details .tags {
	margin-top: 6px;
}
.single-blog-post .blog-details .tags a {
	color: #666;
}
.single-blog-post .blog-details .tags a:hover {
	color: #7c4dff;
	text-decoration: none;
}
.single-blog-post .blog-details .tags span {
	margin: 0 10px;
}
.single-blog-post .blog-post {
	float: right;
	width: 66.66%;
}
.single-blog-post .blog-post .blog-post-content {
	background: #fff;
	padding: 48px 40px;
	box-shadow: 0 2px 4px rgba(0,0,0,.25);
}
.single-blog-post .blog-post .blog-post-content p:last-of-type {
	margin-bottom: 0;
}
@media all and (max-width: 1024px) {
	.single-blog-post .blog-header .content {
		height: auto;
		float: none;
		width: 100%;
	}
	.single-blog-post .blog-header .image {
		width: 100%;
		float: none;
	}
	.single-blog-post .blog-header .content .footer {
		position: static;
		padding: 0;
	}
	.single-blog-post .blog-details, .single-blog-post .blog-post {
		float: none;
		width: 100%;
	}
}
/* Comments
   ========================================================================== */
.blog-post-comments {
	margin-top: 64px;
}
.blog-post-comments>h4 {
	margin-bottom: 48px;
}
.comment {
	margin-bottom: 50px;
}
.comment:last-of-type {
	margin-bottom: 0;
}
.comment .comment {
	margin-left: 58px;
	margin-top: 50px;
}
.comment .inner {
	background: #fff;
	padding: 30px 20px;
	box-shadow: 0 2px 4px rgba(0,0,0,.25);
	position: relative;
}
.comment .inner .image {
	float: left;
	display: inline-block;
	width: 100px;
	height: 100px;
	overflow: hidden;
}
.comment .inner .content {
	margin-left: 130px;
}
.comment .inner .content h5 {
	margin-bottom: 6px;
}
.comment .inner .content p {
	margin-bottom: 6px;
}
.comment .inner .content .meta {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 500;
	color: #232323;
	letter-spacing: 1px;
}
.comment .reply {
	position: absolute;
	top: 30px;
	right: 32px;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1px;
	color: #232323;
}
.comment .reply i {
	color: #7c4dff;
	margin-left: 6px;
}
.comment .reply:hover {
	text-decoration: none;
}
.leave-comment {
	margin-top: 65px;
}
.leave-comment>h4 {
	margin-bottom: 48px;
}
.leave-comment .inner {
	background: #fff;
	padding: 38px 32px;
	box-shadow: 0 1px 3px rgba(0,0,0,.25);
}
.leave-comment .inner label {
	color: #232323;
}

/* ==========================================================================
   19. Error Page
   ========================================================================== */
.error-page {
	text-align: center;
	padding: 72px 0;
}
.error-page .error {
	font-size: 250px;
	line-height: 250px;
	font-weight: 700;
	margin-bottom: 12px;
}
.error-page .details {
	font-size: 16px;
	line-height: 32px;
	color: #232323;
	margin-bottom: 36px;
}
@media all and (max-width: 768px) {
	.error-page .error {
		font-size: 120px;
		line-height: 120px;
	}
}